<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row>
      <v-col v-for="(step, i) in steps" :key="i" cols="12" md="4">
        <div class="d-flex pt-2">
          <v-avatar
            class="elevation-1 mr-4 text-h6"
            :color="step.color"
            size="40"
            left
          >
            {{ i + 1 }}
          </v-avatar>
          <div>
            <h3 class="mt-1 mb-2">
              {{ $t(`steps.${i + 1}.title`) }}
            </h3>
            <p class="mb-0 text-medium-emphasis">
              {{ $t(`steps.${i + 1}.text`) }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
const steps = [
  {
    color: 'awesome-3',
  },
  {
    color: 'awesome-2',
  },
  {
    color: 'awesome-1',
  },
]
</script>


