<template>
  <form ref="form" class="d-flex flex-grow-1" @submit.prevent="analyze">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <div class="d-flex justidfy-space-between">
          <v-text-field
            ref="textField"
            v-model="search"
            class="analyze-bar"
            v-bind="$attrs"
            hide-details
            name="url"
            inputmode="url"
            dir="auto"
            :aria-label="$t('AnalyzeBar.ariaLabel')"
            :label="$t('AnalyzeBar.label')"
            :placeholder="$t('AnalyzeBar.placeholder')"
            :autofocus="autofocus"
            :disabled="
              store.status === 'fetching' ||
              store.statusRequestById === 'fetching'
            "
            :loading="store.statusRequestById === 'fetching'"
            density="comfortable"
            :append-inner-icon="mdiMagnify"
            @click:append-inner="submit"
          >
          </v-text-field>
        </div>
      </v-col>
    </v-row>
    <button style="display: none" ref="submitBtn" type="submit">Submit</button>
  </form>
</template>

<script setup>
import { mdiMagnify } from '@mdi/js'

const autofocus = ref(false)
const submitBtn = ref({})

const store = useStore()
const router = useRouter()

const textField = ref(null)

const submit = () => {
  const { gtag } = useGtag()
  gtag('event', 'search', {
    event_category: 'Search',
    event_label: search.value,
    value: store.url,
  })
  submitBtn.value.click()
}

const search = computed({
  get: () => store.search,
  set: (val) => store.setSearch({ url: val }),
})

const analyze = async (e) => {
  e.preventDefault()

  if (search.value.length < 1) {
    textField.value.focus()
  } else if (
    store.url.length > 0 &&
    store.currentUrl.length > 0 &&
    store.url === store.currentUrl
  ) {
    store.analyze({})
  } else {
    // parse search to remove www.
    const { domainWithoutWww, searchProtocol } = store
    router.push({
      name: 'analysis-website-slug',
      params: { slug: domainWithoutWww },
      query: { protocol: searchProtocol },
    })
  }
}
</script>